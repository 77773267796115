import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApplicationTemplateRequest } from 'src/app/models/application-template-request.model';
import { CreateInputRequest } from 'src/app/models/create-input-request.model';
import { S3ServiceService } from 'src/app/services/s3-service.service';
import { loadSelectedApplicationIsConfigComplete } from 'src/app/store/repository/application/application-repository.actions';
import { environment } from 'src/environments/environment';
import { Application } from 'src/app/models/application.model';
import { TemplateService } from 'src/app/services/template.service';
import { TemplateRequest } from 'src/app/models/template-request.model';
import { interval, Subscription, switchMap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-inputs-table",
  templateUrl: "inputs-table.component.html",
  styleUrls: ['./inputs-table.component.scss']
})

export class InputsTableComponent implements OnInit {
  @Input() application: Application;
  @Input() templateRequests: ApplicationTemplateRequest[];
  @Input() selectedTemplateRequest: ApplicationTemplateRequest;
  @Input() categories: string[];
  public isCollapsed: boolean;

  bucketName: string;
  filesToUpload: { [requestName: string]: FileList; } = {};
  fileNames: string[] = [];
  BasePath: string;
  trainingFiles: { file: File; name: string; status: string }[] = [];
  originalTrainingFiles: { file: File; name: string; status: string }[] = [];
  filesPaths: string[] = [];
  isModified = false;
  removedFiles: string[] = [];

  templateFg: UntypedFormGroup;

  colors: ['primary', 'primary-light-1', 'success', 'info', 'warning', 'danger'];
  categoryMappingColor = {};

  private statusSubscriptions: Subscription[] = [];
  public isSubmitting = false;

  @ViewChild('modalForm', { read: TemplateRef }) modalForm: TemplateRef<any>;
  @ViewChild('htmlEditorModal', { read: TemplateRef }) htmlEditorModal: TemplateRef<any>;
  @ViewChild('trainingModal') trainingModalRef: TemplateRef<any>;

  formModal: BsModalRef;
  form = {
    keyboard: true,
    class: "modal-dialog-centered modal-sm"
  };
  htmlEditorSettings = {
    keyboard: true,
    class: "modal-xxl"
  };

  constructor(
    private templateService: TemplateService,
    private storageService: S3ServiceService,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private store: Store,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.bucketName = environment.OPS_BUCKET;
    this.initForm();
    this.BasePath = `${environment.S3_PREFIXE}/${this.application?.domain.name}`
    this.storageService.basePath = this.BasePath;

  }

  private initForm(): void {
    this.templateFg = this.fb.group({
      value: [""]
    });
    this.filterTemplateRequests(this.categories);
  }

  private getData(): void {
    this.templateService
      .getTemplateRequests(this.application.id)
      .subscribe((response) => {
        this.templateRequests = response.filter(r => r.templateRequest.pageBuilder === false);

        this.filterTemplateRequests(this.categories);
        this.startStatusTracking("t4tr11");

      });

  }

  public createS3Link(filePathS3: any): string {
    if (!filePathS3.value.startsWith('http')) {
      return environment.ID_CLOUDFRONT_CLIENT + '/' + filePathS3.value;
    }
    return filePathS3.value;
  }

  public async submitRequest() {
    try {
      this.isSubmitting = true;

      if (!this.selectedTemplateRequest || !this.selectedTemplateRequest.templateRequest) {
        throw new Error("TemplateRequest n'est pas défini.");
      }

      let request: CreateInputRequest;

      if (['FILE'].includes(this.selectedTemplateRequest.templateRequest.type)) {
        const requestName = this.selectedTemplateRequest.templateRequest.name;
        const files = this.filesToUpload[requestName];
        const requiredMimeTypes = this.selectedTemplateRequest.templateRequest.mimeType.split(',').map(type => type.trim());
        const invalidFiles = Array.from(files).filter(file => !requiredMimeTypes.includes(file.type));
        if (invalidFiles.length > 0) {
          this.isSubmitting = false;

          const mimeTypes = this.selectedTemplateRequest.templateRequest.mimeType;
          const invalidFilesNames = invalidFiles.map(file => file.name).join(', ');
          let warningMessage = this.translate.instant('toastr.invalid_mime_types');
          warningMessage = warningMessage
            .replace('{types}', mimeTypes)
            .replace('{file}', invalidFilesNames);
          const warningTitle = this.translate.instant('toastr.invalid_files');

          this.toastr.warning(`${warningMessage}`, warningTitle);


          return;
        }
        const fileNames = this.filesPaths.map((path) => path.split('/').pop()).join(', ');
        if (this.removedFiles) {
          this.removedFiles.map(async (filePath) => {
            var fullpath = `${this.BasePath}/temp/documentToParse/${filePath}`;
            await this.storageService.deleteObject(fullpath, this.bucketName)

          })
        }
        if (files) {
          await this.storageService.uploadFiles(files, this.filesPaths, this.bucketName);
        }

        request = {
          value: this.filesPaths.join(','),
          displayed: true,
          fileName: fileNames, // Save only the file names
          applicationId: this.application.id,
          templateRequestId: this.selectedTemplateRequest.templateRequest.id,
        };
      } else if (['TEXT', 'COLOR', 'TEXTAREA'].includes(this.selectedTemplateRequest.templateRequest.type)) {
        request = {
          value: this.templateFg.value.value ?? "",
          displayed: true,
          fileName: null,
          applicationId: this.application.id,
          templateRequestId: this.selectedTemplateRequest.templateRequest.id
        };
      }


      this.templateService.createInput(request).subscribe(
        (response) => {
          this.modalService.hide();
          this.templateFg.reset();
          this.getData();
          this.store.dispatch(loadSelectedApplicationIsConfigComplete({ idApplication: this.application.id }));
        },
        (error) => {
          console.error("Erreur lors de la création de l'entrée :", error);
        },
        () => {
          this.isSubmitting = false;
        }
      );
    } catch (error) {
      console.error("Erreur lors de l'envoi :", error);
      this.isSubmitting = false;
    }
  }

  uploadFiles(files: FileList, filePaths: string[], bucketName: string): Promise<void> {
    const uploadPromises = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const path = filePaths[i];
      const uploadPromise = this.storageService.uploadFile(file, path, bucketName); // Méthode uploadFile existante
      uploadPromises.push(uploadPromise);
    }
    return Promise.all(uploadPromises).then(() => {
    });
  }

  closeModal(): void {
    this.modalService.hide(1); // Hides the most recently shown modal
  }

  onFileChange(event, requestName, isConsidered) {
    if (isConsidered) {
      if (event.target.files.length > 0) {
        this.filesPaths = [];
        var files = event.target.files;
        this.setFilesPaths(files, requestName);
        this.templateFg.patchValue({
          value: [this.filesPaths.join(", ")]
        });

        this.filesToUpload[requestName] = files;
      }
    }
  }

  openFormModal(templateRequest: ApplicationTemplateRequest) {
    this.selectedTemplateRequest = templateRequest;

    if (!this.selectedTemplateRequest.templateRequest) {
      console.error("TemplateRequest est indéfini lors de l'ouverture de la modale.");
      return;
    }

    this.templateFg.patchValue({
      value: templateRequest.value,
    });

    this.formModal = this.modalService.show(this.modalForm, this.form);
  }

  openHtmlEditor(templateRequest: ApplicationTemplateRequest) {
    this.selectedTemplateRequest = templateRequest;

    this.formModal = this.modalService.show(this.htmlEditorModal, this.htmlEditorSettings);
  }

  setDisplayableValue(value: string, type: string): string {
    if (!value) return '';

    switch (type) {
      case 'FILE':
        return value.split(',').map(path => path.trim().split('/').pop()).join(', ');
      case 'BOOLEAN':
        return value === 'true' ? 'Oui' : 'Non';
      case 'HTML':
        return 'Editer pour voir';
      default:
        return value;
    }
  }

  public booleanTemplateRequestChanged(value, templateRequest: any) {
    var isChecked = (<HTMLInputElement>value.target).checked;
    const request: CreateInputRequest = {
      value: isChecked.toString(),
      displayed: true,
      applicationId: this.application.id,
      templateRequestId: templateRequest.id
    };
    this.createInput(request);
  }

  submitTemplateRequestAsHtmlContent(content: string) {
    const request: CreateInputRequest = {
      value: content,
      displayed: true,
      applicationId: this.application.id,
      templateRequestId: this.selectedTemplateRequest.templateRequest.id
    };
    this.createInput(request);
  }

  private createInput(request: CreateInputRequest) {
    this.templateService.createInput(request).subscribe(
      (response) => {
        this.templateFg.reset();
        this.getData();
        this.store.dispatch(loadSelectedApplicationIsConfigComplete({ idApplication: this.application.id }));
      }
    );
  }

  getFormControl(controlName: string): FormControl {
    return this.templateFg.get(controlName) as FormControl;
  }
  //########### PICHAT TRAINING T4TR11 (begin) ###############

  private getTrainingFilesName(requestId: string): void {
    const foundRequest = this.templateRequests.find(
      (request) => request.templateRequest.id === requestId
    );

    if (foundRequest) {
      this.selectedTemplateRequest = foundRequest;

      if (foundRequest.value) {
        this.filesPaths = foundRequest.value.split(','); // Sépare les chemins de fichiers par des virgules
        this.trainingFiles = this.filesPaths.map((path) => {

          const fileName = path.split('/').pop(); // Extrait le nom du fichier

          const originalFile = this.originalTrainingFiles.find(
            (original) => original.name === fileName
          );

          return {
            file: originalFile ? originalFile.file : null, // Récupère le fichier réel s'il existe
            name: fileName, // Nom du fichier
            status: 'PENDING', // Statut par défaut
            path, // Chemin complet
          };
        });

        // Initialise les fichiers originaux si ce n'est pas déjà fait
        if (this.originalTrainingFiles.length === 0) {
          this.originalTrainingFiles = [...this.trainingFiles];
        }
      } else {
        this.trainingFiles = [];
      }

    }
  }

  openTrainingModal(request: ApplicationTemplateRequest): void {

    this.selectedTemplateRequest = request;
    this.getTrainingFilesName("t4tr11");

    if (this.formModal) {
      this.formModal.hide();
    }

    this.modalService.show(this.trainingModalRef, { class: 'modal-dialog modal-dialog-centered modal-sm' });
  }

  private createFileList(files: File[]): FileList {
    const dataTransfer = new DataTransfer();
    files.forEach((file) => dataTransfer.items.add(file));
    return dataTransfer.files;
  }

  addTrainingFile(file: File, requestName: string): boolean {
    const filePath = `${this.BasePath}/temp/${requestName}/${file.name}`;

    if (this.trainingFiles.some(f => f.name === file.name)) {
      return false;
    }

    this.trainingFiles.push({ file, name: file.name, status: 'PENDING' });
    this.filesPaths.push(filePath);
    this.checkModification();
    return true;
  }


  onTrainingFileChange(event: any, requestName: string): void {
    const selectedFiles = Array.from(event.target.files) as File[];

    if (selectedFiles.length > 0) {
      selectedFiles.forEach(file => this.addTrainingFile(file, requestName));
      this.filesToUpload[requestName] = this.createFileList(selectedFiles);
    } else {
    }
  }

  removeTrainingFile(index: number): void {
    const removedFile = this.trainingFiles.splice(index, 1)[0];

    // Ajoute le chemin du fichier supprimé à la liste des fichiers supprimés
    if (removedFile && removedFile.name) {
      this.removedFiles.push(removedFile.name);
    }

    // Met à jour la liste des chemins restants
    this.filesPaths = this.filesPaths.filter(path => !path.endsWith(removedFile.name));


    this.checkModification(); // Vérifie si la liste est modifiée
  }

  checkModification(): void {
    // Compare la liste actuelle avec la liste originale
    const currentFileNames = this.trainingFiles.map(f => f.name).sort();
    const originalFileNames = this.originalTrainingFiles.map(f => f.name).sort();
    this.isModified = JSON.stringify(currentFileNames) !== JSON.stringify(originalFileNames);
  }

  private startStatusTracking(templateRequestId: string): void {
    this.templateRequests.forEach((request) => {
      if (request.templateRequest.id === templateRequestId) {
        const statusSubscription = interval(5000) // Toutes les 5 secondes
          .pipe(
            switchMap(() =>
              this.templateService.getInput(this.application.id, request.templateRequest.id)
            )
          )
          .subscribe({
            next: (updatedRequest) => {

              // Met à jour le statut de la demande spécifique
              request.status = updatedRequest.status;

              if (updatedRequest.status === 'COMPLETED' || updatedRequest.status === 'ERROR') {
                statusSubscription.unsubscribe(); // Arrêtez la souscription pour cet ID
              }
            },
            error: (error) => {
              console.error('Error updating status:', error);
            },
            complete: () => {
            },
          });

        this.statusSubscriptions.push(statusSubscription);
      }
    });
  }

  public filterTemplateRequests(categories) {
    // filter templateRequests given the categories
    this.templateRequests = this.templateRequests.filter(r => (r.templateRequest.pageBuilder === false) && (r.templateRequest.configurable)).filter((obj) => (categories.indexOf(obj.templateRequest.category) != -1));

  }

  private setFilesPaths(files: any[], templateRequestName: string) {
    for (let i = 0; i < files.length; i++) {
      this.filesPaths.push(this.BasePath + `/temp/${templateRequestName}/${files[i].name}`);
    }
    //this.filesPaths = files.map(file => this.basePath + `/temp/${templateRequestName}/${file.name}`);
    //let path = `${this.application.domain.url}/src/assets/img/client/background/background.jpg`;
  }
  //########### PICHAT TRAINING T4TR11 (end) ###############

}