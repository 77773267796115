<span *ngIf="fileOrFolder" id="{{ fileOrFolder.id }}" [ngClass]="isActiv ? 'd-flex align-items-center justify-content-between activ bg-pi-blue-fluo' : 'd-flex align-items-center justify-content-between'">
  <ngx-file-drop *ngIf="isAFolder" dropZoneLabel="" (onFileDrop)="dropAndUpload($event)"
  (onFileOver)="fileOver($event, 'row')" dropZoneClassName="ngx-file-drop-style" contentClassName="clean_nativ_css">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector" class="filetext">
        <div id="{{ fileOrFolder.path }}" class="ellipsis" title="{{ fileOrFolder.key }}">
        <!-- Image dynamique -->
        <img *ngIf="!showDefaultIcon && fileExtensionImageUrl" 
        [src]="fileExtensionImageUrl" 
        class="icon-size mx-2 my-2" 
        alt="{{ fileExtension }} icon" 
        (error)="handleImageError()" />
        {{ fileOrFolder.key_decoded }}
        </div>
      </ng-template>
  </ngx-file-drop>
  <div *ngIf="!isAFolder && fileOrFolder.key" class="filetext">
    <div id="{{ fileOrFolder.path }}" class="ellipsis" title="{{ fileOrFolder.key }}">
      
      <!-- Image dynamique -->
      <img *ngIf="!showDefaultIcon && fileExtensionImageUrl" 
           [src]="fileExtensionImageUrl" 
           class="icon-size mx-2 my-2" 
           alt="{{ fileExtension }} icon" 
           (error)="handleImageError()" />

      <!-- Icône par défaut -->
      <i *ngIf="showDefaultIcon" class="icon-size fas fa-file mx-2 my-2 "></i>
      {{ fileOrFolder.key_decoded }}
    </div>
  </div>
</span>
