import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, Renderer2, afterNextRender, HostListener } from "@angular/core";

import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { Template } from "src/app/models/template.model";
import { LanguageService } from "src/app/services/language.service";
import { loadUserById, logout } from "src/app/store/auth/auth.actions";
import { selectIsAuth } from "src/app/store/auth/auth.selectors";
import { selectTemplateType, selectUrlOrderFromStep } from "src/app/store/order/order.selectors";
import { selectTemplates } from "src/app/store/repository/repository.selector";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-navbar-public",
  templateUrl: "./navbar-public.component.html",
  styleUrls: ["./navbar-public.component.scss"]
})
export class NavbarPublicComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  public isCollapsed = true;
  displayedDropNav: string;
  isAuth$: Observable<boolean>;
  urlOrderFromStep$: Observable<string[]>;
  currentLang: string;
  templateType: string;
  picture: string;
  templates$: Observable<Template[]>;
  isAppSearchActivated = false;

  @ViewChild('products') productsNav: ElementRef;
  @ViewChild('resources') resourcesNav: ElementRef;
  @ViewChild('productsButton') productsButton: ElementRef;
  @ViewChild('resourcesButton') resourcesButton: ElementRef;
  @ViewChild('navbarCollapse') navbarCollapse: ElementRef; 
  @ViewChild('navbarToggler') navbarToggler: ElementRef;
  private clickListener: () => void;

  isExplorerActivated = environment.isExplorerActivated;

  constructor(private store: Store,
    public router: Router,
    private languageService: LanguageService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2) {

    this.currentLang = this.languageService.getLanguage();

    afterNextRender(() => {
      this.renderer.listen('window', 'click',(e:Event)=>{

        if(this.productsNav !== undefined &&
          !this.productsButton.nativeElement.contains(e.target)
          && !this.productsNav.nativeElement.contains(e.target)) {
          this.displayedDropNav = '';
          this.changeDetectorRef.detectChanges();

          return;
        }

        if(this.resourcesNav !== undefined &&
          !this.resourcesButton.nativeElement.contains(e.target)
          && !this.resourcesNav.nativeElement.contains(e.target)) {
          this.displayedDropNav = '';
          this.changeDetectorRef.detectChanges();

          return;
          }
      })

    });

  }

  ngOnInit(): void {
    const _templateType = this.store.select(selectTemplateType).subscribe((templateType) => {
      this.templateType = templateType;
    });
    this.subscriptions.add(_templateType);

    this.templates$ = this.store.select(selectTemplates);

    this.isAuth$ = this.store.select(selectIsAuth);
    this.urlOrderFromStep$ = this.store.select(selectUrlOrderFromStep);

    this._userId();
      this.clickListener = this.renderer.listen('document', 'click', (event: Event) => {
        this.handleClickOutside(event);
      });
  }

  goToCreateAppForm() {
    this.router.navigate(['/store', this.templateType, 'order']);
  }

  logout() {
    localStorage.clear();
    this.store.dispatch(logout());
  }

  switchLanguage(language: string) {
    this.languageService.setLanguage(language)
    this.currentLang = language
  }

  private _userId() {
    this.isAuth$ = this.store.select(selectIsAuth)
    this.isAuth$.subscribe((isAuth) => {
      if (isAuth) {
         this.store.dispatch(loadUserById());
      }
    });
  }

  redirectToResourcePage(resource: string) {
    this.clearDropNav();
    this.router.navigate([`/${resource}`]);
  }

  handleTemplateSearch(searchTerm: string) {
    this.router.navigate(['/explore'], { queryParams: { query: searchTerm } });
  }

  handleAppSearch(searchTerm: string) {
    console.log('Search term:', searchTerm);
    // Perform the search logic here
  }

  setDropNav(dropNav: string) {
    // If the dropdown is already open and the same menu is clicked again, close it
    if (this.displayedDropNav === dropNav) {
      this.clearDropNav();  // Close the dropdown
    } else {
      this.isAppSearchActivated = false;
      this.displayedDropNav = dropNav;  // Open the clicked dropdown
    }
  }

  clearDropNav() {
    this.displayedDropNav = '';
    this.isCollapsed = true;
  }
  handleClickOutside(event: Event): void {
    const target = event.target as HTMLElement;
    if (
      this.navbarCollapse &&
      this.navbarToggler &&
      !this.navbarCollapse.nativeElement.contains(target) &&
      !this.navbarToggler.nativeElement.contains(target)
    ) {
      this.clearDropNav(); // Fermez la navbar
    }
  }
  redirectToProductPage(templateName: string) {
    this.router.navigate([`/store/${templateName}`]);
  }

  ngOnDestroy(): void {
    if (this.clickListener) {
      this.clickListener();
    }
    this.subscriptions.unsubscribe();
  }
}
