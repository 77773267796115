<div class="card collapsed">
  <a  class="collapsed" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseEvent" href="javascript:void(0)">
    <div class="card-header border-0">
      <div class="row">
        <div class="col-6">
          <h3 class="mb-0">
            <i [ngClass]="isCollapsed ? 'fa fa-chevron-down pr-3' : 'fa fa-chevron-up pr-3'"></i>
            <ng-container>
              {{'itc.' + categories |translate}}
            </ng-container>
          </h3>
        </div>
      </div>
    </div>

  </a>
  <div id="collapseEvent" class="table-responsive tableFixHead" [collapse]="isCollapsed" [isAnimated]="false">
    <table class="table align-items-center table-flush table-striped">
      <thead class="thead-light">
        <tr>
          <th></th>
          <th>{{'itc.h1'|translate}}</th>
          <th>{{'itc.h2'|translate}}</th>
          <th>{{'itc.h3'|translate}}</th>
          <th>{{'itc.h4'|translate}}</th>
        </tr>
      </thead>

      <!-- TABLE -->
      <tbody>
        <tr *ngFor="let request of templateRequests">
          <td class="table-position">
            <span class="badge badge-pill text-white"  [ngStyle]="{'background-color': request.templateRequest.color}">{{ request.templateRequest.subCategory }}</span>
          </td>

          <td class="table-user">
            <b> {{'template_request.'+request?.templateRequest?.descriptionKey+'.description'|translate }} </b>
          </td>

          <td>
            <app-input-status [status]="request.status"></app-input-status>
          </td>

          <td>
            <p class="line-break m-auto text-justify custom-overflow" style="line-height: 1rem;">
              <small>{{ setDisplayableValue(request.value, request.templateRequest.type) }} </small>
            </p>
          </td>

          <td class="table-actions">
            <!-- FILE, TEXT, COLOR, TEXTAREA -->
            <a
            *ngIf="['FILE', 'TEXT', 'COLOR', 'TEXTAREA'].includes(request.templateRequest.type)"
            class="table-action text-primary"
            [tooltip]="request.templateRequest.type === 'FILE' ? ('itc.upload' | translate) : ('itc.edit' | translate)"
            placement="top"
            data-target="#modal-form"
            data-toggle="modal"
            (click)="request.templateRequest.id !== 't4tr11' ? openFormModal(request) : openTrainingModal(request)">
            <i [ngClass]="request.templateRequest.id !== 't4tr11' ? 
                          (request.templateRequest.type === 'FILE' ? 'fas fa-upload' : 'fas fa-edit') : 
                          'fas fa-file-upload'"></i>
          </a>
          

            <a
              *ngIf="request.status == 'COMPLETED' && request.templateRequest.type === 'FILE' && request.templateRequest.id != 't4tr11'"
              
              [tooltip]="('itc.open'|translate)"
              placement="top"
              class="table-action text-primary" [attr.href]="createS3Link(request)" target="_blank">
              <i class="fas fa-external-link-alt"></i>
            </a>

            <!-- BOOLEAN -->
            <label class="custom-toggle" *ngIf="request.templateRequest.type === 'BOOLEAN'">
              <input
                class="form-control"
                type="checkbox"
                (change)="booleanTemplateRequestChanged($event, request.templateRequest)"
                [checked]="request.value == 'true'"/>

              <span
                class="custom-toggle-slider rounded-circle"
                data-label-off="Non"
                data-label-on="Oui">
              </span>
            </label>

            <!-- HTML -->
            <a *ngIf="request.templateRequest.type === 'HTML'"
              class="table-action"
                [tooltip]="('itc.edit'|translate)"
              placement="top"
              (click)="openHtmlEditor(request)">
              <i class="fas fa-edit"> </i>
            </a>

            <ng-template #modalForm>
              <div class="modal-body p-0">
                <div class="card bg-light border-0 mb-0">
                  <div class="card-body px-lg-5 py-lg-5">
                    <div class="text-center mb-4">
                        <p>{{ 'template_request.' + selectedTemplateRequest?.templateRequest?.descriptionKey + '.action' | translate }}</p>
                    </div>

                    <form [formGroup]="templateFg" (ngSubmit)="submitRequest()">
                      <div class="form-group mb-3">
                        <div class="input-group input-group-alternative">
                          <div class="input-group-prepend"></div>
                          <!-- TEXT CASE -->
                          <input
                            *ngIf="selectedTemplateRequest?.templateRequest?.type === 'TEXT'"
                            class="form-control"
                            placeholder="{{'template_request.' + selectedTemplateRequest?.templateRequest?.descriptionKey + '.placeholder' | translate }}"
                            [formControl]="getFormControl('value')"
                            type="text"
                            name="value"
                            />
                          <!-- FILE CASE -->
                          <input
                            *ngIf="selectedTemplateRequest?.templateRequest?.type === 'FILE'"
                            class="form-control"
                            placeholder="{{'template_request.' + selectedTemplateRequest?.templateRequest?.descriptionKey + '.placeholder' | translate }}"
                            type="file"
                            name="value"
                            (change)="onFileChange($event, selectedTemplateRequest?.templateRequest?.name, selectedTemplateRequest?.templateRequest?.type === 'FILE')"
                            />
                          <!-- COLOR CASE -->
                          <input
                            *ngIf="selectedTemplateRequest?.templateRequest?.type === 'COLOR'"
                            class="form-control"
                            placeholder="{{'template_request.' + selectedTemplateRequest?.templateRequest?.descriptionKey + '.placeholder' | translate }}"
                            [formControl]="getFormControl('value')"
                            type="color"
                            name="value"
                            />
                          <!-- TEXTAREA CASE -->
                          <textarea
                            *ngIf="selectedTemplateRequest?.templateRequest?.type === 'TEXTAREA'"
                            class="form-control"
                            placeholder="{{'template_request.' + selectedTemplateRequest?.templateRequest?.descriptionKey + '.placeholder' | translate }}"
                            [formControl]="getFormControl('value')"
                            type="text"
                            name="value"
                            rows="8">
                          </textarea>
                        </div>
                      </div>
                      <div class="text-center">
                        <button type="submit" class="btn btn-primary my-4">
                          {{'itc.h7'|translate}}
                        </button>
                      </div>
                    </form>
                    <div class="preloader" *ngIf='this.selectedTemplateRequest.templateRequest.id=="t4tr11" && isSubmitting'>
                      <div class="loader">
                        <div class="book"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template #htmlEditorModal>
              <div class="modal-body p-0">
                <div class="card bg-light border-0 mb-0">
                  <div class="card-body px-lg-5 py-lg-5">
                    <pi-html-editor [initialContent]="selectedTemplateRequest.value" (submitHtmlOutput)="submitTemplateRequestAsHtmlContent($event)"></pi-html-editor>
                  </div>
                </div>
              </div>
            </ng-template>
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #trainingModal>
  <div class="modal-header d-flex justify-content-between align-items-center">
    <h5 class="modal-title mb-0" id="trainingModalLabel">Fichiers d’entraînement</h5>
    <!-- Bouton "+" dans un cercle vert -->
    <label
      for="fileInput"
      class="btn btn-success rounded-circle d-flex justify-content-center align-items-center"
      style="width: 40px; height: 40px;">
      <i class="fas fa-plus"></i>
    </label>
    <input
      id="fileInput"
      type="file"
      class="d-none"
      multiple
      (change)="onTrainingFileChange($event, selectedTemplateRequest?.templateRequest?.name)"
    />
  </div>
  <div class="modal-body">
    <!-- Liste des fichiers avec scroll bar -->
    <div
      class="file-list-container"
      *ngIf="trainingFiles.length > 0"
      style="max-height: 150px; overflow-y: auto;"
    >
      <div
        *ngFor="let file of trainingFiles; let i = index"
        class="d-flex align-items-center mb-2"
      >
  <i class="fas fa-file-pdf text-danger mr-2"></i>
  <span class="flex-grow-1 text-truncate" title="{{ file.name }}">
    {{ file.name }}
  </span>
  <button class="btn btn-danger btn-sm ml-2" (click)="removeTrainingFile(i)">
    <i class="fas fa-trash-alt"></i>
  </button>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!-- Bouton pour envoyer -->
    <button
      class="btn btn-primary"
      [disabled]="!isModified || trainingFiles.length === 0"
      (click)="submitRequest()">
      Entraîner
    </button>
  </div>
</ng-template>
