<div class="main-wrapper bg-primary">
    <img src="../../../../assets/img/logo/logo_white.png" width="150" class="img-fluid ml-2 mt-1" />
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <img src="../../../../assets/img/infography/app-loading-people.png" class="img-fluid" />
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-12">
                <h1 class="display-3 text-white text-center">{{'app_loading.thank_you'|translate}} {{ application.admin.firstname }} !</h1>
                <span class="loader text-center mt-4"></span>

                <p class="text-white text-center mt-4">{{ messageTranslateKey | translate}}
                    <br/>  {{'app_loading.waiting_time'|translate}}<strong>{{ application.template.estimatedCreationTime }} min</strong>
                </p>
            </div>
            <div class="col text-center">
                <button type="button" class="btn btn-light text-center my-3" (click)="redirectToDashboard()">
                    {{'app_loading.go_to_dashboard'|translate}}
                </button>
            </div>
        </div>
    </div>
</div>
