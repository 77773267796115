import { Component, Input, OnInit } from "@angular/core";
import { environment } from "@env/environment";
import { NgxFileDropEntry } from "ngx-file-drop";

@Component({
  selector: 'app-filesystem-folder',
  templateUrl: 'filesystem-folder.component.html',
  styleUrls: ['./filesystem-folder.component.scss']
})
export class FilesystemFolderComponent implements OnInit {
  @Input() fileOrFolder: any;
  @Input() dropAndUpload: (filesNgx: NgxFileDropEntry[]) => void;
  @Input() fileOver: (event, destination) => void;
  @Input() isActiv: boolean = false;
  isAFolder: boolean;
  fileExtension: string | null = null;
  fileExtensionImageUrl: string | null = null;
  showDefaultIcon: boolean = false; // Si true, affiche l'icône par défaut
  readonly seoUrl =environment.seoUrl;
  readonly supportedExtensions: string[] = [
    '3ds', 'aac', 'ai', 'avi', 'bmp', 'cad', 'cdr', 'css', 'csv', 'dat',
    'default', 'dll', 'dmg', 'doc', 'docx', 'eps', 'fla', 'flv', 'gif', 'html',
    'indd', 'iso', 'jar', 'jpg', 'js', 'midi', 'mov', 'mp3', 'mp4', 'mpg', 'pdf',
    'php', 'png', 'ppt', 'pptx', 'ps', 'psd', 'py', 'raw', 'sql', 'svg', 'tif',
    'txt', 'webp', 'wmv', 'xls', 'xlsx', 'xml', 'zip'
  ];
  
  constructor() {}

  ngOnInit(): void {
    this.isAFolder = this.fileOrFolder.children.length !== 0;

    if (!this.isAFolder) {
      this.fileExtension = this.getFileExtension(this.fileOrFolder.key_decoded);
      if (this.fileExtension && this.supportedExtensions.includes(this.fileExtension)) {
        this.fileExtensionImageUrl = `${this.seoUrl}/t3/assets/img/pirog/files_extensions/${this.fileExtension}.png`;
      } else {
        this.showDefaultIcon = true;
    }
    }
    else{
      this.fileExtensionImageUrl = `${this.seoUrl}/t3/assets/img/pirog/files_extensions/folder.png`;

    }
  }

  private getFileExtension(fileName: string): string | null {
    const dotIndex = fileName.lastIndexOf('.');
    return dotIndex !== -1 ? fileName.substring(dotIndex + 1).toLowerCase() : null;
  }
  
  handleImageError(): void {
    this.showDefaultIcon = true; 
  }
}
